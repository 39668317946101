import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/src/components/layout.js";
import SubpageHero from "../components/subpage-hero";
import NewsletterSection from "../components/newsletter-section";
import TextImage from "../components/text-image";
import Seo from "../components/seo";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <Seo title="Polityka Prywatności Fundacji Enjoy The Ride" mdxType="Seo" />
    <SubpageHero mdxType="SubpageHero">
      <h1>{`Polityka prywatności`}</h1>
      <p>{`FUNDACJA ENJOY THE RIDE`}</p>
    </SubpageHero>
    <div className="etr-container">
      <div className="max-w-content-lg mx-auto">
        <h2>{`W JAKIM CELU SĄ PRZETWARZANE MOJE DANE?`}</h2>
        <p>{`Administrator przetwarzana Twoje dane osobowe w celu wysyłania Ci własnych informacji handlowych oraz treści promujących produkty i działalność administratora – na podstawie art. 6 ust. 1 lit. a rozporządzenia 2016/679 – a więc Twojej zgody. Dodatkowo, Twoje dane osobowe będą przetwarzane do celów statystycznych i analitycznych, `}</p>
        <br />
        <h2>{`KTO BĘDZIE ODBIORCĄ MOICH DANYCH?`}</h2>
        <p>{`Twoje dane osobowe są ujawniane podmiotom współpracującym z administratorem. Takie podmioty nazywa się odbiorcami danych. Ujawnienie danych przez administratora jest możliwe co do zasady w następujących przypadkach:`}</p>
        <ul>
          <li parentName="ul">{`gdy obowiązek taki wynika z przepisów prawa – dotyczy to ujawniania przez administratora Twoich danych;`}</li>
          <li parentName="ul">{`gdy administrator musi ujawnić dane by móc zrealizować umowę z dostawcą systemu rozsyłającego newsletter – działanie takie zawsze odbywa się na podstawie zawartej umowy powierzenia przetwarzania danych;`}</li>
          <li parentName="ul">{`gdy administrator decyduje się na ujawnienie danych osobowych z powodu własnego, prawnie uzasadnionego interesu;`}</li>
          <li parentName="ul">{`gdy wynika to z wyrażonej przez Ciebie zgody na przetwarzanie danych.`}</li>
        </ul>
        <p>{`Wszystkie powyższe przypadku są praktykami w pełni zgodnymi z RODO. `}</p>
        <p>{`Podmiotami, którym administrator ujawni Twoje dane osobowe będą:`}</p>
        <ul>
          <li parentName="ul">{`UAB “MailerLite” (Litwa, Europa);`}</li>
        </ul>
        <p>{`Będziemy przekazywać Twoje dane osobowe do innych państw, niż należące do Europejskiego Obszaru Gospodarczego – tzw. państw trzecich. Uznaliśmy, że jest to niezbędne w celu zapewnienia prawidłowego działania newslettera. Dokonaliśmy oceny skutków dla ochrony danych, w ramach której porównywaliśmy różne dostępne systemy wysyłki newslettera. Niestety żaden z systemów tego typu, który działał wyłącznie na obszarze Europejskiego Obszaru Gospodarczego, nie dawał rękojmi działania w sposób integralny, poufny i zapewniający oczekiwaną przez administratora dostępność. Wybrane przez nas rozwiązania wyróżniają się na tym tle, lecz korzystanie z nich wiąże się z przetwarzaniem danych osobowych również poza Europejskim Obszarem Gospodarczym. Aby przeciwdziałać ewentualnym ryzykom, zawarliśmy umowy powierzenia przetwarzania zapewniające odpowiedni poziom bezpieczeństwa Twoich danych. Przekazywane danych poza Europejski Obszar Gospodarczy jest w nich oparte o standardowe klauzule umowne zatwierdzone przez Komisję Europejską. W ramach oceny skutków dla ochrony danych, zgodnie z zaleceniami po wyroku Trybunały Sprawiedliwości Unii Europejskiej c-311/18, określiliśmy ryzyko i ewentualne skutki związane z przekazywaniem danych. Taki sposób przetwarzania jest w pełni legalny i zgodny z RODO.`}</p>
        <br />
        <h2>{`JAK DŁUGO BĘDĄ PRZETWARZANE MOJE DANE?`}</h2>
        <p>{`Będziemy przetwarzać Twoje dane osobowe przez czas trwania zgody. W chwili gdy cofniesz zgodę, Twoje dane zostaną bezpowrotnie usunięte i ich przetwarzanie zakończy się.`}</p>
        <br />
        <h2>{`JAKIE PRAWA PRZYSŁUGUJĄ MI W ZWIĄZKU Z PRZETWARZANIEM MOICH DANYCH OSOBOWYCH?`}</h2>
        <p>{`Przysługuje Tobie prawo do żądania od sprostowania, usunięcia, ograniczenia lub wniesienia sprzeciwu dotyczącego przetwarzania Twoich danych osobowych. Prawa te przysługują Tobie tylko w sytuacjach, w których wniesienie takiego żądania jest faktycznie uzasadnione. Przysługuje Tobie także prawo do cofnięcia w dowolnym momencie zgody na przetwarzanie Twoich danych osobowych. Cofnięcie zgody nie wpłynie na zgodność z prawem przetwarzania danych realizowanego do chwili jej cofnięcia.`}</p>
        <br />
        <h2>{`CZY MUSZĘ PODAWAĆ MOJE DANE OSOBOWE?`}</h2>
        <p>{`Nie musisz podawać swoich danych osobowych. Pamiętaj tylko, że bez podania swoich danych osobowych (adresu e-mail) nie będziesz w stanie zapisać się na newsletter i otrzymywać informacji w nim zawartych.`}</p>
        <br />
        <h2>{`ZAUTOMATYZOWANE PRZETWARZANIE DANYCH OSOBOWYCH`}</h2>
        <p>{`Twoje dane osobowe będą przetwarzane w sposób zautomatyzowany, ale nie będą podlegały profilowaniu.`}</p>
        <br />
        <h2>{`PRAWO DO SKARGI`}</h2>
        <p>{`Przysługuje Tobie prawo do wniesienia skargi w związku z tym jak przetwarzane są Twoje dane osobowe do organu nadzorczego, którym jest Prezes Urzędu Ochrony Danych Osobowych z siedzibą w Warszawie (00-193), przy ul. Stawki 2.`}</p>
        <br /> <br /> 
      </div>
    </div>
    <NewsletterSection mdxType="NewsletterSection" />

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      